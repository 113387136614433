'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { REGIONAL_CENTERS, SERVICE_ROLE_TYPES, SERVICE_ROLES } from '@/common/constants';
import { LOCATIONS } from '@/common/constants/locations';

export interface FilterMyClientsParams {
  regionalCenter?: string;
  serviceRoleType?: string;
  location?: string;
}

interface Props {
  value?: FilterMyClientsParams;
  onApply: (value: FilterMyClientsParams) => void;
  onCancel: () => void;
}

export function MyClientsFilterForm({ value, onApply, onCancel }: Props) {
  const t = useTranslations();

  const form = useForm<FilterMyClientsParams>({
    defaultValues: {
      regionalCenter: value ? value.regionalCenter : '',
      serviceRoleType: value ? value.serviceRoleType : '',
      location: value ? value.location : '',
    },
  });

  const onSubmit: SubmitHandler<FilterMyClientsParams> = (data: FilterMyClientsParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="location"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.setting.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.setting.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SERVICE_ROLES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
          <div className="relative mb-2">
              <FormField
                control={form.control}
                name="serviceRoleType"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.serviceRoleType.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.serviceRoleType.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {SERVICE_ROLE_TYPES.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="lg" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button type="submit" size="lg" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
