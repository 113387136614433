'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Pagination } from '@/components/common';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { useTranslations } from 'next-intl';
import { clearFilter, parseFilterToArrayOfTagsData, sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { TableFilter } from '@/components/common/table/table-filter';
import { useIsAllowed } from '@/lib/RBAC';
import { UploadFileDialogRef } from '@/components/common/upload-file.dialog';

import { Signature } from '../interfaces/signature.interface';
import { FilterSignaturesParams, SignaturesFilterForm } from '../header-filter/signatures-filter-form';
import { useSignaturesQuery } from '@/hooks/fetchers/queries/shifts/useSignaturesQuery';
import { getSignaturesColumns } from './signatures-columns';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '@/views/all-shifts/preview/preview-shift.dialog';
import { TableTypes } from '@/common/enums/common';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';


const PER_PAGE = 200;

export function SignaturesTable() {
  const t = useTranslations();
  const [data, setData] = useState<Signature[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [filter, setFilter] = useState<FilterSignaturesParams>({});
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();

  const { data: tableConfig } = useTableSettingsQuery(TableTypes.SIGNATURES);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData } = useSignaturesQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    ...filter,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Signature>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);


  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);
  
  const table = useReactTable({
    columns: getSignaturesColumns({t}),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Signature>[]) => {
    setTableConfig({
      name: TableTypes.SIGNATURES,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };


  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Signature>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          formComponent={SignaturesFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-248px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewShiftDialogRef.current?.openById(rowOriginal.id)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      <PreviewShiftDialog ref={previewShiftDialogRef} />
    </>
  );
}
