import { Pagination, ScrollArea } from '@/components/common';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAdminsQuery } from '@/hooks/fetchers/queries/admins/useAdminsQuery';
import { useDepartmentsQuery } from '@/hooks/fetchers/queries/departments/useDepartmentsQuery';
import { useGuardiansQuery } from '@/hooks/fetchers/queries/guardians/useGuardiansQuery';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { cn, compileFullName } from '@/lib/utils';
import { SendbirdChatWith, User } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Department, DepartmentDetails } from '../interfaces/department.interface';
import { useDepartmentQuery } from '@/hooks/fetchers/queries/departments/useDepartmentQuery';

const PER_PAGE = 200;

enum TabsChooseMember {
  GUARDIANS = 'GUARDIANS',
  THERAPISTS = 'THERAPISTS',
  ADMINS = 'ADMINS',
  DEPARTMENTS = 'DEPARTMENTS',
}

interface ChooseMemberDialogProps {
  sb: SendbirdChatWith<GroupChannelModule[]>;
  handleCreate: (userId: string, nickname: string) => void;
  handleCreateGroup: (department: DepartmentDetails) => void;
}

export type ChooseMemberDialogRef = {
  open: () => void;
};

const ChooseMemberDialog = forwardRef<ChooseMemberDialogRef, ChooseMemberDialogProps>(
  ({ handleCreate, handleCreateGroup, sb }, ref) => {
    const t = useTranslations();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [keywordAdmin, setKeywordAdmin] = useState('');
    const [keywordDepartment, setKeywordDepartment] = useState('');
    const [keywordGuardian, setKeywordGuardian] = useState('');
    const [keywordTherapist, setKeywordTherapist] = useState('');
    const [departmentId, setDepartmentId] = useState<string>();
    const [paginationTherapist, setPaginationTherapist] = useState<{ page: number; perPage: number }>({
      page: 0,
      perPage: PER_PAGE,
    });
    const [paginationGuardian, setPaginationGuardian] = useState<{ page: number; perPage: number }>({
      page: 0,
      perPage: PER_PAGE,
    });
    const [paginationAdmin, setPaginationAdmin] = useState<{ page: number; perPage: number }>({
      page: 0,
      perPage: PER_PAGE,
    });
    const [paginationDepartment, setPaginationDepartment] = useState<{ page: number; perPage: number }>({
      page: 0,
      perPage: PER_PAGE,
    });

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
    }));

    const {
      data: departmentDetails,
      refetch: refetchDepartment,
      isFetched,
      isFetching,
    } = useDepartmentQuery(departmentId, { enabled: false, refetchOnWindowFocus: isOpen });

    useEffect(() => {
      setKeywordAdmin('');
      setKeywordDepartment('');
      setKeywordGuardian('');
      setKeywordTherapist('');
      setDepartmentId(undefined);
    }, [isOpen]);

    useEffect(() => {
      if (departmentId) {
        refetchDepartment();
      }
    }, [departmentId]);

    useEffect(() => {
      if (departmentDetails) {
        handleCreateGroup(departmentDetails);
        setIsOpen(false);
      }
    }, [isFetched, isFetching]);

    const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery(
      {
        search: keywordTherapist,
        skip: paginationTherapist.page * paginationTherapist.perPage,
        take: paginationTherapist.perPage,
      },
      { enabled: true, refetchOnWindowFocus: isOpen }
    );

    const { data: guardians, isLoading: isLoadingGuardians } = useGuardiansQuery(
      {
        search: keywordGuardian,
        skip: paginationGuardian.page * paginationGuardian.perPage,
        take: paginationGuardian.perPage,
      },
      { enabled: true, refetchOnWindowFocus: isOpen }
    );

    const { data: admins, isLoading: isLoadingAdmins } = useAdminsQuery(
      {
        search: keywordAdmin,
        skip: paginationAdmin.page * paginationAdmin.perPage,
        take: paginationAdmin.perPage,
      },
      { enabled: true, refetchOnWindowFocus: isOpen }
    );

    const { data: departments, isLoading: isLoadingDepartments } = useDepartmentsQuery(
      {
        search: keywordDepartment,
        skip: paginationDepartment.page * paginationDepartment.perPage,
        take: paginationDepartment.perPage,
      },
      { enabled: true, refetchOnWindowFocus: isOpen }
    );

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="min-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.Chats.createNewChat')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <Tabs defaultValue={TabsChooseMember.THERAPISTS}>
            <TabsList className="w-full">
              <TabsTrigger className="w-full" value={TabsChooseMember.THERAPISTS}>
                {t('Buttons.therapists')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TabsChooseMember.GUARDIANS}>
                {t('Buttons.guardians')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TabsChooseMember.ADMINS}>
                {t('Buttons.admins')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TabsChooseMember.DEPARTMENTS}>
                {t('Buttons.departments')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value={TabsChooseMember.THERAPISTS} className="pt-4">
              <div className="h-[calc(100vh-228px)]">
                <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordTherapist(e.target.value)} />
                <ul className="mt-6">
                  <ScrollArea className="h-[calc(100vh-336px)]">
                    {therapists?.data.map((user) => (
                      <li
                        onClick={() => {
                          handleCreate(user.id, `${user.firstName} ${user.lastName}`);
                          setIsOpen(false);
                        }}
                        className={cn(
                          'border-grey-lighter flex min-h-9 cursor-pointer items-center border-b px-3',
                          user.id === sb.currentUser?.userId && 'pointer-events-none opacity-40'
                        )}
                        key={user.id}
                      >
                        {compileFullName(user)}
                      </li>
                    ))}
                  </ScrollArea>
                </ul>

                {therapists && therapists.totalCount > 0 && (
                  <Pagination
                    className="mt-4"
                    changeCurrentPage={(page) =>
                      setPaginationTherapist((prev) => ({
                        ...prev,
                        page: page - 1,
                      }))
                    }
                    totalSize={therapists && therapists.totalCount ? therapists.totalCount : 0}
                    sizePerPage={paginationTherapist.perPage}
                    currentPage={paginationTherapist.page + 1}
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TabsChooseMember.GUARDIANS} className="pt-4">
              <div className="h-[calc(100vh-228px)]">
                <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordGuardian(e.target.value)} />
                <ul className="mt-6">
                  <ScrollArea className="h-[calc(100vh-336px)]">
                    {guardians?.data.map((user) => (
                      <li
                        onClick={() => {
                          handleCreate(user.id, `${user.firstName} ${user.lastName}`);
                          setIsOpen(false);
                        }}
                        className={cn(
                          'border-grey-lighter flex min-h-9 cursor-pointer items-center border-b px-3',
                          user.id === sb.currentUser?.userId && 'pointer-events-none opacity-40'
                        )}
                        key={user.id}
                      >
                        {compileFullName(user)}
                      </li>
                    ))}
                  </ScrollArea>
                </ul>

                {guardians && guardians.totalCount > 0 && (
                  <Pagination
                    className="mt-4"
                    changeCurrentPage={(page) =>
                      setPaginationGuardian((prev) => ({
                        ...prev,
                        page: page - 1,
                      }))
                    }
                    totalSize={guardians && guardians.totalCount ? guardians.totalCount : 0}
                    sizePerPage={paginationGuardian.perPage}
                    currentPage={paginationGuardian.page + 1}
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TabsChooseMember.ADMINS} className="pt-4">
              <div className="h-[calc(100vh-228px)]">
                <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordAdmin(e.target.value)} />
                <ul className="mt-6">
                  <ScrollArea className="h-[calc(100vh-336px)]">
                    {admins?.data.map((user) => (
                      <li
                        onClick={() => {
                          handleCreate(user.id, `${user.firstName} ${user.lastName}`);
                          setIsOpen(false);
                        }}
                        className={cn(
                          'border-grey-lighter flex min-h-9 cursor-pointer items-center border-b px-3',
                          user.id === sb.currentUser?.userId && 'pointer-events-none opacity-40'
                        )}
                        key={user.id}
                      >
                        {compileFullName(user)}
                      </li>
                    ))}
                  </ScrollArea>
                </ul>

                {admins && admins.totalCount > 0 && (
                  <Pagination
                    className="mt-4"
                    changeCurrentPage={(page) =>
                      setPaginationAdmin((prev) => ({
                        ...prev,
                        page: page - 1,
                      }))
                    }
                    totalSize={admins && admins.totalCount ? admins.totalCount : 0}
                    sizePerPage={paginationAdmin.perPage}
                    currentPage={paginationAdmin.page + 1}
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TabsChooseMember.DEPARTMENTS} className="pt-4">
              <div className="h-[calc(100vh-228px)]">
                <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordDepartment(e.target.value)} />
                <ul className="mt-6">
                  <ScrollArea className="h-[calc(100vh-336px)]">
                    {departments?.data.map((department) => (
                      <li
                        onClick={() => setDepartmentId(department.id)}
                        className={cn('border-grey-lighter flex min-h-9 cursor-pointer items-center border-b px-3')}
                        key={department.id}
                      >
                        {department.name}
                      </li>
                    ))}
                  </ScrollArea>
                </ul>

                {departments && departments.totalCount > 0 && (
                  <Pagination
                    className="mt-4"
                    changeCurrentPage={(page) =>
                      setPaginationDepartment((prev) => ({
                        ...prev,
                        page: page - 1,
                      }))
                    }
                    totalSize={departments && departments.totalCount ? departments.totalCount : 0}
                    sizePerPage={paginationDepartment.perPage}
                    currentPage={paginationDepartment.page + 1}
                  />
                )}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    );
  }
);

ChooseMemberDialog.displayName = 'ChooseMemberDialog';

export { ChooseMemberDialog };
