'use client';
import Folder from './../../../public/icons/folder-blue.svg';
import Calendar from './../../../public/icons/calendar-blue.svg';
import Wallet from './../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { StatisticBadge } from '@/components/common/statistic-badge';
import { TimesheetDetailsTable } from './table/timesheet-details-table';
import { useTimesheetQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetQuery';
import dayjs from '@/lib/dayjsConfig';
import { minutesToTime } from '@/lib/utils';

export function TimesheetDetailsView({ timesheetId }: { timesheetId: string }) {
  const t = useTranslations();

  const { data: timesheet, refetch: refetchTimesheet } = useTimesheetQuery(timesheetId);

  return (
    <main className="pl-4 pr-6">
      <h1 className="py-4 text-2xl font-bold">{t('Pages.Timesheets.titleDetails')}</h1>
      <div className="mb-6 flex flex-row space-x-4">
        <StatisticBadge
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Calendar}
          label={t('Pages.Timesheets.period')}
          value={
            timesheet
              ? `${dayjs(timesheet.periodFrom).format('MMM')} 1-${dayjs(timesheet.periodTo).format('DD')}`
              : '---'
          }
        />
        {timesheet && timesheet.report ? (
          <>
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Wallet}
              label={t('Pages.Timesheets.totalPay')}
              value={timesheet.report.totalPay ? `$${timesheet.report.totalPay.toFixed(2)}` : `---`}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Calendar}
              label={t('Pages.Timesheets.totalHours')}
              value={timesheet.report.totalDuration ? minutesToTime(timesheet.report.totalDuration) : `---`}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Folder}
              label={t('Pages.Timesheets.totalShifts')}
              value={timesheet.report.shiftsCount ? `${timesheet.report.shiftsCount}` : `---`}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Folder}
              label={t('Pages.Timesheets.totalReports')}
              value={timesheet.report.reportsCount ? `${timesheet.report.reportsCount}` : `---`}
            />
          </>
        ) : null}
      </div>

      <TimesheetDetailsTable
        timesheetId={timesheetId}
        status={timesheet?.status}
        period={
          timesheet ? `${dayjs(timesheet.periodFrom).format('MMM')} 1-${dayjs(timesheet.periodTo).format('DD')}` : '---'
        }
      />
    </main>
  );
}
