'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import {
  ConfirmDialog,
  Pagination,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
  toast,
  ToastAction,
  ToastTypeEnums,
} from '@/components/common';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { Button } from '@/components/ui';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { getServiceRolesColumns } from './service-roles-columns';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { useTranslations } from 'next-intl';
import { sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { ServiceRole } from '../interfaces/service-role.interface';
import { useServiceRolesQuery } from '@/hooks/fetchers/queries/service-roles/useServiceRolesQuery';
import { ManageServiceRoleForm } from '../create/manage-service-role-form';
import { useRemoveServiceRoleMutation } from '@/hooks/fetchers/mutations/service-roles/useRemoveServiceRoleMutation';
import { useRemoveUndoServiceRoleMutation } from '@/hooks/fetchers/mutations/service-roles/useRemoveUndoServiceRoleMutation';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TableTypes } from '@/common/enums/common';

const PER_PAGE = 200;

export function ServiceRolesTable() {
  const t = useTranslations();
  const [data, setData] = useState<ServiceRole[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createSheetOpen, setCreateSheetOpen] = useState(false);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();
  const { data: tableConfig } = useTableSettingsQuery(TableTypes.SERVICE_ROLES);
  const { mutate: setTableConfig } = useTableSettingsMutation();

  const { data: tableData, refetch: refetchServiceRoles } = useServiceRolesQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<ServiceRole>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const { mutate: removeServiceRole } = useRemoveServiceRoleMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoServiceRoles(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchServiceRoles();
    },
  });

  const { mutate: removeUndoServiceRoles } = useRemoveUndoServiceRoleMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchServiceRoles();
    },
  });

  const handleConfirmRemove = (row: Row<ServiceRole>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteServiceRole'),
      value: row.original.id,
    });
  };

  const table = useReactTable({
    columns: getServiceRolesColumns({
      t,
      onConfirmRemove: handleConfirmRemove,
      isCanRemove: checkPermissions([PermissionsTypes.SOFT_DELETE_SERVICE_ROLE])
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<ServiceRole>[]) => {
    setTableConfig({
      name: TableTypes.SERVICE_ROLES,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<ServiceRole>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between">
        <h1 className="py-4 text-2xl font-bold">{t('Pages.ServiceRoles.title')}</h1>
        <div className="flex flex-row items-center space-x-2">
          {checkPermissions([PermissionsTypes.CREATE_SERVICE_ROLE]) && (
            <Sheet open={createSheetOpen} onOpenChange={setCreateSheetOpen}>
              <SheetTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 h-4 w-4" /> {t('Buttons.addServiceRole')}
                </Button>
              </SheetTrigger>
              <SheetContent>
                <SheetTitle>
                  <VisuallyHidden.Root>Add New Service Role</VisuallyHidden.Root>
                </SheetTitle>
                <SheetDescription></SheetDescription>
                <header className="flex h-16 flex-row items-center bg-primary p-6">
                  <h3 className="text-base font-medium text-white">{t('Pages.ServiceRoles.addNewServiceRole')}</h3>
                </header>
                <div className="w-full px-6">
                  <ManageServiceRoleForm
                    onCancel={() => setCreateSheetOpen(false)}
                    onUpdate={() => {
                      setCreateSheetOpen(false);
                      refetchServiceRoles();
                    }}
                  />
                </div>
              </SheetContent>
            </Sheet>
          )}
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-196px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable table={table} />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeServiceRole(id)}
        title={t('Pages.ServiceRoles.deleteServiceRole')}
        type="delete"
      />
    </>
  );
}
