'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import {
  ConfirmDialog,
  Pagination,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
  toast,
  ToastAction,
  ToastTypeEnums,
} from '@/components/common';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { Button } from '@/components/ui';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { getAdminUsersListColumns } from './admin-users-list-columns';
import { ManageAdminForm } from '../manage-admin-form';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { useTranslations } from 'next-intl';
import { Admin } from '../interfaces/admins.interface';
import { useAdminsQuery } from '@/hooks/fetchers/queries/admins/useAdminsQuery';
import { sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { useRemoveAdminMutation } from '@/hooks/fetchers/mutations/admins/useRemoveAdminMutation';
import { useRemoveUndoAdminMutation } from '@/hooks/fetchers/mutations/admins/useRemoveUndoAdminMutation';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { PreviewAdminDialog, PreviewAdminDialogRef } from '../preview-admin.dialog';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TableTypes } from '@/common/enums/common';

const PER_PAGE = 200;

export function AdminUsersListTable() {
  const t = useTranslations();
  const [data, setData] = useState<Admin[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createSheetOpen, setCreateSheetOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewAdminDialogRef = createRef<PreviewAdminDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TableTypes.ADMIN_USERS_LIST);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData, refetch: refetchAdmins } = useAdminsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Admin>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const { mutate: removeAdmin } = useRemoveAdminMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoAdmin(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchAdmins();
    },
  });

  const { mutate: removeUndoAdmin } = useRemoveUndoAdminMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchAdmins();
    },
  });

  const handleConfirmRemove = (row: Row<Admin>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteAccount', { name: `${row.original.firstName} ${row.original.lastName}` }),
      value: row.original.id,
    });
  };

  const table = useReactTable({
    columns: getAdminUsersListColumns({
      t,
      onConfirmRemove: handleConfirmRemove,
      isCanRemove: checkPermissions([PermissionsTypes.SOFT_DELETE_ADMIN_USER])
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Admin>[]) => {
    setTableConfig({
      name: TableTypes.ADMIN_USERS_LIST,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Admin>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <h1 className="py-4 text-2xl font-bold">{t('Pages.AdminUsersList.title')}</h1>
        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {checkPermissions([PermissionsTypes.CREATE_NEW_ADMIN]) && (
            <Sheet open={createSheetOpen} onOpenChange={setCreateSheetOpen}>
              <SheetTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 h-4 w-4" /> {t('Buttons.addAdmin')}
                </Button>
              </SheetTrigger>
              <SheetContent>
                <SheetTitle>
                  <VisuallyHidden.Root>Add New Admin</VisuallyHidden.Root>
                </SheetTitle>
                <SheetDescription></SheetDescription>
                <header className="flex h-16 flex-row items-center bg-primary p-6">
                  <h3 className="text-base font-medium text-white">{t('Pages.AdminUsersList.addNewAdmin')}</h3>
                </header>
                <div className="w-full p-6">
                  <ManageAdminForm
                    onCancel={() => setCreateSheetOpen(false)}
                    onUpdate={() => {
                      setCreateSheetOpen(false);
                      refetchAdmins();
                    }}
                  />
                </div>
              </SheetContent>
            </Sheet>
          )}
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-196px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable table={table} onClickByRow={(rowOriginal) => previewAdminDialogRef.current?.open(rowOriginal)} />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeAdmin(id)}
        title={t('Common.deleteAccount')}
        type="delete"
      />

      <PreviewAdminDialog ref={previewAdminDialogRef} />
    </>
  );
}
