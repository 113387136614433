/**
 * An array of routes that are accessible to the public
 * These routes do not require token
 * @type {string[]}
 */
export const publicRoutes =  [
    '/',
    'health-check',
    '/guardian-signature',
    '/mobile-app-only',
    '/auth/login',
    '/auth/forgot-password',
    '/auth/forgot-password/confirmation',
    '/auth/setup-new-password',
  ];
/**
 * Route that is used for authentication
 * @type {string}
 */
export const signInRoute = '/auth/login';


/**
 * The default redirect path after logging in
 * @type {string}
 */
export const DEFAULT_LOGIN_REDIRECT = '/dashboard';
