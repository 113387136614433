'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { Pagination } from '@/components/common';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { useTranslations } from 'next-intl';
import { clearFilter, parseFilterToArrayOfTagsData, sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { TableFilter } from '@/components/common/table/table-filter';
import { AvailableClientsFilterForm, FilterAvailableParams } from '../header-filter/available-clients-filter-form';
import { AvailableClient } from '../interfaces/available-clients.interface';
import { useAvailableClientsQuery } from '@/hooks/fetchers/queries/services/useAvailableClientsQuery';
import {
  PreviewAvailableClientDialog,
  PreviewAvailableClientDialogRef,
} from '../preview/preview-available-client.dialog';
import { getAvailableClientsColumns } from './available-clients-columns';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TableTypes } from '@/common/enums/common';

const PER_PAGE = 200;

export function AvailableClientsTable() {
  const t = useTranslations();
  const [data, setData] = useState<AvailableClient[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [filter, setFilter] = useState<FilterAvailableParams>({});
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewAvailableClientDialogRef = createRef<PreviewAvailableClientDialogRef>();

  const { data: tableConfig } = useTableSettingsQuery(TableTypes.AVAILABLE_CLIENTS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData, refetch: refetchAvailableClients } = useAvailableClientsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    ...filter,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<AvailableClient>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);

  const table = useReactTable({
    columns: getAvailableClientsColumns({ t }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<AvailableClient>[]) => {
    setTableConfig({
      name: TableTypes.AVAILABLE_CLIENTS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<AvailableClient>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          formComponent={AvailableClientsFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-248px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            pinnedLastCol={false}
            table={table}
            onClickByRow={(rowOriginal) => previewAvailableClientDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      <PreviewAvailableClientDialog onRefresh={refetchAvailableClients} ref={previewAvailableClientDialogRef} />
    </>
  );
}
