import { cn, getColorForServiceRole } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import { MySchedule } from './my-schedule.interface';
import { SHIFT_STATUS_TYPES } from '@/common/constants/common';
import { Chips } from '@/components/common';
import { EventInput } from '@fullcalendar/core/index.js';

interface ShiftEvent extends EventInput {
  extendedProps: MySchedule;
}

export function EventRender(eventInfo: ShiftEvent) {
  if (!eventInfo) {
    return null;
  }

  const duration = dayjs(eventInfo.event.end).diff(eventInfo.event.start, 'minute');

  let trimClass;
  if (duration >= 60) {
    trimClass = 'line-clamp-2';
  } else {
    trimClass = 'truncate';
  }

  let colorStatus: string;
  switch (eventInfo.event.extendedProps.status) {
    case SHIFT_STATUS_TYPES.DRAFT:
      colorStatus = 'bg-[#427AA11A] text-[#36617E]';
      break;
    case SHIFT_STATUS_TYPES.CANCELED:
      colorStatus = 'bg-[#CECED61A] text-[#B7B7C1]';
      break;
    case SHIFT_STATUS_TYPES.COMPLETED:
      colorStatus = 'bg-[#1B8A6B1A] text-[#1B8A6B]';
      break;
    case SHIFT_STATUS_TYPES.NO_SHOW:
      colorStatus = 'bg-[#877C7C1A] text-[#877C7C]';
      break;
    case SHIFT_STATUS_TYPES.PENDING:
      colorStatus = 'bg-[#F4743B1A] text-[#DA6A38]';
      break;
    case SHIFT_STATUS_TYPES.SCHEDULED:
      colorStatus = 'bg-[#6794361A] text-[#567B2F]';
      break;
    default:
      colorStatus = 'bg-green-500';
      break;
  }

  return (
    <div
      className={cn(
        'cursor-pointer flex h-full flex-col justify-between rounded-sm border-2 border-blue-200 bg-white',
        eventInfo.status === SHIFT_STATUS_TYPES.NO_SHOW &&
          'bg-[repeating-linear-gradient(-45deg,_#F5F8FF,_#F5F8FF_15px,_#E6EEFF_5px,_#E6EEFF_30px)]'
      )}
    >
      <div className="p-1">
        <p className={cn('px-2 text-sm font-medium text-blue-950', trimClass)}>{eventInfo.event.title}</p>
        <Chips
          size="sm"
          color={getColorForServiceRole(
            eventInfo.event.extendedProps.serviceRole.title,
            eventInfo.event.extendedProps.serviceRole.speciality.code
          )}
          title={eventInfo.event.extendedProps.serviceRole.title}
        />
      </div>
      <p
        className={`whitespace-nowrap rounded-b px-2 text-xs font-light ${colorStatus}`}
      >{`${dayjs(eventInfo.event.extendedProps.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')} - ${dayjs(eventInfo.event.extendedProps.endAt).utcOffset(dayjs().format('Z')).format('h:mm A')}`}</p>
    </div>
  );
}
