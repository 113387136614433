'use client';

import { ScrollArea } from '@radix-ui/react-scroll-area';
import { useTranslations } from 'next-intl';
import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { FilterAuditLogForm, FilterAuditLogParams } from './filter-audit-log-form';
import { AuditLogFilter } from './audit-log-filter';
import { clearFilter, parseFilterToArrayOfTagsData } from '@/components/common/table/utils';
import { useActivityLogsQuery } from '@/hooks/fetchers/queries/activity-logs/useActivityLogsQuery';
import { Pagination } from '@/components/common';
import { AuditLogItem } from './audit-log-item';
import { AuditLog, User } from './audit-log.interface';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '../all-services/preview/preview-service.dialog';
import { PreviewClientDialog, PreviewClientDialogRef } from '../all-clients/preview/preview-client.dialog';
import { PreviewTherapistDialog, PreviewTherapistDialogRef } from '../all-therapists/preview/preview-therapist.dialog';
import { PreviewDocumentDialog, PreviewDocumentDialogRef } from '../all-documents/preview/preview-documents.dialog';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '../all-guardians/preview/preview-guardian.dialog';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '../all-shifts/preview/preview-shift.dialog';
import { ACTIVITY_ENTITY_TYPES } from '@/common/constants/common';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { AuditLogPreview, AuditLogPreviewProps } from './audit-log-preview';
import { compareObjects } from './utils';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';
import { PreviewAdminDialog, PreviewAdminDialogRef } from '../admin-users-list/preview-admin.dialog';

const PER_PAGE = 200;

export function AuditLogView() {
  const t = useTranslations();
  const [filter, setFilter] = useState<FilterAuditLogParams>({});
  const [auditLogPreviewData, setAuditLogPreviewData] = useState<AuditLogPreviewProps | null>(null);
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const previewDocumentDialogRef = createRef<PreviewDocumentDialogRef>();
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();
  const previewAdminDialogRef = createRef<PreviewAdminDialogRef>();
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const { data: logs, refetch: refetchLogsList } = useActivityLogsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    ...filter,
  });

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);

  const previewLogDetails = (log: AuditLog): void => {
    const { action, entity, new: current, old: previous, updatedAt, user } = log;
    const changes = compareObjects(previous, current, t);

    setAuditLogPreviewData({
      action,
      entity,
      changes,
      updatedAt,
      user,
      log,
    });
  };

  const openPreviewUser = (user: User) => {
    switch (user.role) {
      case RoleTypes.Admin:
      case RoleTypes.Coordinator:
      case RoleTypes['Super admin']:
        previewAdminDialogRef.current?.open(user);
        break;
      case RoleTypes.Therapist:
      case RoleTypes.Assistant:
      case RoleTypes['Therapist Limited']:
      case RoleTypes['Assistant Limited']:
        previewTherapistDialogRef.current?.openById(user.id);
        break;
      default:
        break;
    }
  };

  const openPreviewEntity = (log: AuditLog) => {
    switch (log.entity) {
      case ACTIVITY_ENTITY_TYPES.CLIENTS:
        previewClientDialogRef.current?.openById(log.entity_id);
        break;
      case ACTIVITY_ENTITY_TYPES.SHIFTS:
        previewShiftDialogRef.current?.openById(log.entity_id);
        break;
      case ACTIVITY_ENTITY_TYPES.SERVICES:
        previewServiceDialogRef.current?.openById(log.entity_id);
        break;
      case ACTIVITY_ENTITY_TYPES.DOCUMENTS:
        previewDocumentDialogRef.current?.openById(log.entity_id);
        break;
      case ACTIVITY_ENTITY_TYPES.CLIENT_GUARDIANS:
        previewGuardianDialogRef.current?.openById(log.entity_id);
        break;
      case ACTIVITY_ENTITY_TYPES.THERAPIST_RATES:
      case ACTIVITY_ENTITY_TYPES.THERAPIST_SUPERVISORS:
      case ACTIVITY_ENTITY_TYPES.THERAPIST_WORK_LOGS:
        previewTherapistDialogRef.current?.openById(log.entity_id);
        break;

      default:
        break;
    }
  };

  return (
    <main>
      <div className="grid h-[calc(100vh-64px)] w-full grid-cols-2">
        <div className="border-r border-slate-200">
          <header className="flex flex-row items-center justify-between px-4 py-4">
            <h1 className="text-2xl font-bold">{t('Pages.AuditLog.title')}</h1>
            <AuditLogFilter
              filter={filter}
              clearFilter={clearFilter}
              parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
              onUpdateFilter={setFilter}
              formComponent={FilterAuditLogForm}
            />
          </header>
          <div className="ml-3">
            <SimpleSearch placeholder={t('Pages.AuditLog.searchByUser')} onSearchChange={handleSearchFilter} />
          </div>
          <ScrollArea className="h-[calc(100vh-240px)] overflow-auto">
            {logs?.data.map((item) => (
              <AuditLogItem
                onPreviewUser={openPreviewUser}
                onPreviewEntity={openPreviewEntity}
                onPreview={previewLogDetails}
                key={item.id}
                value={item}
              />
            ))}
          </ScrollArea>
          {logs && logs.totalCount > 0 && (
            <Pagination
              className="mt-4"
              changeCurrentPage={(page) =>
                setPagination((prev) => ({
                  ...prev,
                  page: page - 1,
                }))
              }
              totalSize={logs && logs.totalCount ? logs.totalCount : 0}
              sizePerPage={pagination.perPage}
              currentPage={pagination.page + 1}
            />
          )}
        </div>

        <div>
          {auditLogPreviewData ? (
            <AuditLogPreview
              {...auditLogPreviewData}
              onPreviewUser={openPreviewUser}
              onPreviewEntity={openPreviewEntity}
            />
          ) : (
            <div className="flex h-[calc(100vh-64px)] w-full flex-col items-center justify-center">
              <h1 className="rounded-md bg-blue-50 px-6 py-2">{t('Pages.AuditLog.detailsWillBeDisplayedHere')}</h1>
            </div>
          )}
        </div>
      </div>

      <PreviewClientDialog onUpdated={refetchLogsList} ref={previewClientDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchLogsList} ref={previewTherapistDialogRef} />
      <PreviewServiceDialog onUpdated={refetchLogsList} ref={previewServiceDialogRef} />
      <PreviewDocumentDialog onUpdated={refetchLogsList} ref={previewDocumentDialogRef} />
      <PreviewGuardianDialog onUpdated={refetchLogsList} ref={previewGuardianDialogRef} />
      <PreviewShiftDialog onUpdated={refetchLogsList} ref={previewShiftDialogRef} />
      <PreviewAdminDialog onUpdated={refetchLogsList} ref={previewAdminDialogRef} />
    </main>
  );
}
